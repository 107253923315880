/* src/App.css */
.employee-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 18px;
  text-align: left;
  border-radius: 10px;
}

.employee-table th,
.employee-table td {
  padding: 12px 15px;
}

.employee-table th {
  background-color: #54cfe5;
}

.employee-table tr {
  border-bottom: 1px solid #dddddd;
}

.employee-table tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.employee-table tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.employee-table td input,
.employee-table td .css-yk16xz-control {
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

.employee-table td {
  position: relative;
}

.employee-table .fa-edit,
.employee-table .fa-save,
.employee-table .fa-times {
  cursor: pointer;
  margin-left: 10px;
}
